<template>
	<section class="signup-webinar-form">
		<webinar-form :id="id"/>
	</section>
</template>

<script>
import WebinarForm from '@/components/forms/Webinar.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
	components: {
		'webinar-form': WebinarForm
	},
	props: ['id'],
	computed: mapGetters(['isShowSingUpForm']),
	methods: mapActions(['fetchAllBusinessTypes']),
	created() {
		this.fetchAllBusinessTypes()
	}
}
</script>

<style>

</style>
